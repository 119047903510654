@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;0,900;1,300&display=swap");
:root {
  --la-light-green: #bff1ba;
  --la-green: #73d473;
  --la-dark-gray: #666;
  --la-black: #333;
  --la-off-white: #f2f2f2;
  --border-radius: 0.25rem;
  --background-color: var(--la-off-white);
  --background-accent: #b5b5b5;
  --font-color: var(--la-black);
  --svg-background: url("/images/bg-white.svg");
}

[data-theme="dark"] {
  --background-color: var(--la-black);
  --font-color: var(--la-off-white);
  --svg-background: url("/images/bg-dark.svg");
  --background-accent: #1e1e1e;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
/*general site styles*/
body {
  background: var(--svg-background);
  color: var(--font-color);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

a {
  color: inherit;
  text-decoration: none;
  padding: 0.75rem;
  font-size: 1.2rem;
}

h1,
h2,
h3 {
  -webkit-margin-before: 0.5rem;
          margin-block-start: 0.5rem;
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
}

label {
  color: var(--font-color);
  padding: 0.5rem;
}
::placeholder {
  color: var(--font-color);
  opacity: 0.7;
}

#main {
  width: 100%;
  margin: 0 auto;
}

.section {
  max-width: 60rem;
  min-height: 85svh;
  height: 100%;
  -webkit-padding-before: 5rem;
          padding-block-start: 5rem;
  -webkit-padding-after: 1rem;
          padding-block-end: 1rem;
  padding-inline: 4rem;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
}

.btn {
  background-color: var(--la-light-green);
  color: var(--la-black);
  padding: 1rem;
  font-weight: 700;
  border: none;
  border-radius: var(--border-radius);
  text-align: center;
  cursor: pointer;
  box-shadow: 0.1rem 0.1rem 0.1rem var(--background-accent);
}

.bg {
  background: var(--svg-background);
}

/*Navbar*/
nav {
  background-color: var(--background-color);
  position: fixed;
  padding: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;
}

.nav-links a:hover,
.nav-links a:focus,
.active {
  font-weight: bold;
}

.logo {
  margin-right: auto;
  display: flex;
  cursor: pointer;
}

.logo a {
  margin: 0;
  padding: 0;
}

#avatar {
  border-radius: 50%;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
  padding: 0.25rem;
  display: flex;
}

.nav-links {
  display: flex;
  align-items: center;
}

.my-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my-title-container em {
  font-size: 0.8rem;
}

/*mobile Navbar*/
.mobile-nav-button {
  color: var(--font-color);
  position: relative;
  display: none;
  background-color: transparent;
  border: none;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.nav-mobile {
  position: fixed;
  width: 100%;
  max-height: 0;
  display: flex;
  flex-direction: column;
  -webkit-padding-before: 4rem;
          padding-block-start: 4rem;
  transition: all 0.5s ease;
  overflow-y: hidden;
  z-index: 1;
  
}

.nav-mobile.open {
  background-color: var(--background-color);
  max-height: 500px;
  border-bottom: 0.1rem solid;
}

.nav-mobile a {
  padding-block: 0.75rem;
  width: 100%;
}

.nav-mobile a:last-child {
  border: none;
}

/*header component*/
.header {
  text-align: center;
  -webkit-margin-after: 2rem;
          margin-block-end: 2rem;
  padding-inline: 0.5rem;
  z-index: 1;
}
.header h2 {
  font-size: 2.5rem;
}

/*home section*/
.main {
  font-size: 1.5em;
  text-align: center;
  position: relative;
  width: 100%;
}

.main p {
  max-width: 40rem;
  margin: 0 auto 2rem;
}

.p-3 {
  padding-inline: 3rem;
}

.social-links-main {
  position: absolute;
  left: 0;
  list-style: none;
  padding: 0;
  background-color: transparent;
}

/*about section*/

.cv-link {
  color: rgb(59, 164, 59);
  padding: 0;
  font-size: 1.1rem;
  text-decoration: underline;
}

.skill-list {
  list-style: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.skill-list li {
  background-color: var(--la-light-green);
  color: var(--la-black);
  font-weight: 700;
  padding: 0.5rem;
  border-radius: var(--border-radius);
  border: 0.1rem solid;
}

/*projects section*/
.projects {
  min-height: 60vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.project-container {
  text-align: center;
}

.project-image {
  border-radius: var(--border-radius);
}

/*form*/
form {
  background-color: transparent;
  border-radius: var(--border-radius);
  max-width: 100%;
  padding: 1rem;
}

input {
  line-height: 30px;
}

input,
textarea {
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  color: var(--la-black);
  font-size: 1rem;
  border: 2px solid transparent;
  border-radius: var(--border-radius);
  -webkit-margin-before: 0.5rem;
          margin-block-start: 0.5rem;
  -webkit-margin-after: 2rem;
          margin-block-end: 2rem;
  padding: 0.5rem;
  background-color: #fff;
}
textarea {
  height: 100px;
  resize: none;
  margin-bottom: 0;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0.1rem 0.1rem 0.25rem var(--background-accent);
}

label {
  color: var(--font-color);
  width: 100%;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 700;
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
}
form .btn{
  float:right
}
.message-length {
  display: block;
  -webkit-padding-after: 1rem;
          padding-block-end: 1rem;
  border-radius: var(--border-radius);
  text-align: right;
  opacity: 0.7;
  font-size: 0.8rem;
}

.message {
  width: 100%;
  padding: 1rem;
  font-weight: bold;
  opacity: 0.7;
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
}

.input-error {
  border: 2px solid red;
}

.error {
  color: red;
  font-weight: bold;
  letter-spacing: 0.07rem;
}

.success {
  color: green;
  background-color: rgb(148, 250, 148);
  border: 0.1rem solid green;
}

/*footer*/
footer {
  text-align: center;
  -webkit-padding-before: 1rem;
          padding-block-start: 1rem;
}

footer ul {
  position: fixed;
  top: 33%;
  left: 0;
  list-style: none;
  padding: 0;
  background-color: transparent;
}

footer span {
  display: block;
  font-size: 0.8rem;
  padding-block: 0.5rem;
}

/*media queries*/
@media (max-width: 768px) {
  #main {
    width: 100%;
  }
  .section{
    padding-inline: 1rem
  }
  .nav-links,
  .social-links-main {
    display: none;
  }
  .mobile-nav-button {
    display: flex;
  }
  .section{
    padding-inline:1rem
  }
  .btn {
    width: 100%;
  }
  footer>ul{
    position: relative;
    margin: 0;
    display: flex;
    justify-content: center;
  }
}

/* Custom Dark Mode Toggle Element */

.toggle-theme input[type="checkbox"] {
  display: none;
}

.toggle-theme {
  position: relative;
  display: inline-flex;
  margin: 0.5rem;
  height: 1.5rem;
  width: 3rem;
}

.slider {
  background-color: #ccc;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.slider:before {
  content: "";
  background-color: #fff;
  position: absolute;
  width: 1rem;
  height: 1rem;
  bottom: 0.25rem;
  left: 0.25rem;
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(1.5rem);
}

input:checked + .slider {
  background-color: var(--background-accent);
}

input::placeholder,
textarea::placeholder {
  color: var(--la-dark-gray);
}

.slider.round {
  border-radius: 1rem;
}

.slider.round:before {
  border-radius: 50%;
}

